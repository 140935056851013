<template>
  <Stack
    tag="div"
    gap="xs"
    justify="between"
    align="start"
    class="ui-w-[calc(100%-32px)] sm:ui-w-[420px] ui-px-xs ui-py-xs ui-rounded-sm"
    :class="variantClasses[variant]"
    data-test-id="notification-toast"
  >
    <Stack gap="xs" align="center">
      <Icon name="warning-solid" :size="16" />
    </Stack>
    <Stack direction="col" class="ui-w-[80%]">
      <Stack gap="2xs" direction="col">
        <Text size="sm" weight="bold">{{ title }}</Text>
        <slot />

        <Text
          is="body"
          size="sm"
          weight="bold"
          class="ui-text-mkm-blue-light ui-cursor-pointer"
          @click="handleAction"
        >
          {{ action }}
        </Text>
      </Stack>
    </Stack>
    <Stack
      gap="xs"
      align="center"
      @click="handleClick"
      class="ui-cursor-pointer"
    >
      <Icon name="times-thin" :size="16" />
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import type { ToastTypes } from "./types";
import Text from "../../Typography/Text/Text.vue";
import Stack from "../../Layout/Stack/Stack.vue";
import Icon from "../../Icon/Icon.vue";

const { variant, action, onClick, onAction } = defineProps<ToastTypes>();

const handleClick = () => onClick && onClick();
const handleAction = () => onAction && onAction();

const variantClasses = {
  ["error"]: "ui-bg-red-lighter ui-text-red-default",
  ["warning"]: "ui-bg-orange-lighter ui-text-orange-dark",
  ["neutral"]: "ui-bg-grey-lighter ui-text-charcoal-default",
  ["information"]: "ui-bg-blue-lighter ui-text-blue-dark",
  ["success"]: "ui-bg-green-lighter ui-text-green-dark",
};
</script>
