<template>
  <Transition name="toast-slide">
    <Toast
      v-show="toast"
      variant="error"
      title="We're having trouble fetching your data."
      :onClick="() => setToast(false)"
      action="Refresh"
      :onAction="refreshPage"
      class="fixed bottom-lg right-xs z-[60] toast shadow-sm"
    >
      <Text size="sm" class="ui-text-charcoal-default">
        Please try closing other MKM tabs or refreshing the page.
      </Text>
    </Toast>
  </Transition>
</template>

<script lang="ts" setup>
const { toast, setToast } = useUIState();

const router = useRouter();
const refreshPage = () => {
  router.go(0);
};
</script>

<style>
.toast-slide-enter-active {
  animation: slideInRight 0.5s ease-out;
}
.toast-slide-leave-active {
  animation: slideOutRight 0.5s ease-in forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
